import React, { SVGProps, useEffect } from "react";
import { Events } from "../types";
import MultiplePinOutline from "./MultiplePinOutline";
import PinOutline from "./PinOutline";

const PIN_HEIGHT = 42;
const PIN_WIDTH = 30;

function Pin({
    events,
    active,
    ...props
}: SVGProps<SVGElement> & { events: Events; active: boolean }) {
    const eventIds = events?.map((event) => event?.category?.id);

    const allSameCategory = eventIds?.every((id) => id === eventIds?.[0]);

    const svg = allSameCategory ? events?.[0]?.category?.svgUrl : null;

    const number = events.length;

    const eventWithCustomColor = events?.find((event) =>
        Boolean(event.customPinColor)
    );

    const color = eventWithCustomColor?.customPinColor
        ? eventWithCustomColor.customPinColor
        : "#138601";

    const highlightedColor = shadeColor(color, 40);

    if (!events?.length) {
        return null;
    }

    return (
        <div
            className="view fade"
            style={{ animationDelay: `${Math.random()}s` }}
        >
            <div
                className={
                    !allSameCategory && number
                        ? ".containerMultiple"
                        : ".container"
                }
            >
                {!allSameCategory && number && (
                    <>
                        <MultiplePinOutline
                            fill={active ? highlightedColor : color}
                            opacity={1}
                            width={42}
                            height={42}
                        />
                    </>
                )}

                {allSameCategory && (
                    <PinOutline
                        fill={active ? highlightedColor : color}
                        width={PIN_WIDTH}
                        height={PIN_HEIGHT}
                    />
                )}

                {allSameCategory && Boolean(svg) && (
                    <img
                        className="svg"
                        color="white"
                        style={{ fill: "white" }}
                        src={svg!}
                        width={16}
                        height={16}
                        alt={"category"}
                    />
                )}

                {!allSameCategory && number && (
                    <p className="number">{number}</p>
                )}
            </div>
        </div>
    );
}

export function shadeColor(color: string, percent: number) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(((R * (100 + percent)) / 100).toString(), 10);
    G = parseInt(((G * (100 + percent)) / 100).toString(), 10);
    B = parseInt(((B * (100 + percent)) / 100).toString(), 10);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR =
        R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    var GG =
        G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    var BB =
        B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
}

export default React.memo(Pin);
