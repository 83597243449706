import { ReactComponent as LocationIcon } from "../icons/address.svg";

interface Props {
    children: React.ReactNode;
    onPopup(): void;
}

export default function Link({ children, onPopup }: Props) {
    return (
        <div>
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                        width: "100%",
                    }}
                >
                    <p className="icon-and-text">
                        <LocationIcon
                            width={"1em"}
                            height={"1em"}
                            fill="#333333"
                            color="#333333"
                        />
                        {children}
                    </p>
                    <a
                        href="#"
                        style={{ textDecoration: "none" }}
                        onClick={onPopup}
                    >
                        Get Directions
                    </a>
                </div>
            </div>
        </div>
    );
}
