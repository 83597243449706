import { useEffect, useState } from "react";

export default function useResize() {
    const [width, setWidth] = useState(() => window.innerWidth);
    const [height, setHeight] = useState(() => window.innerHeight);

    function resize() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", resize);

        return () => window.removeEventListener("resize", resize);
    }, []);

    return {
        width,
        height,
    };
}
