import React, { SVGProps } from "react";
import PinOutline from "./PinOutline";

const PIN_HEIGHT = 42;
const PIN_WIDTH = 30;

function CustomPin({
    active,
    color,
    svg,
    ...props
}: SVGProps<SVGElement> & { svg: string; color: string; active: boolean }) {
    const highlightedColor = shadeColor(color, 40);

    return (
        <div
            className="view fade"
            style={{ animationDelay: `${Math.random()}s` }}
        >
            <div className={".container"}>
                <PinOutline
                    fill={active ? highlightedColor : color}
                    width={PIN_WIDTH}
                    height={PIN_HEIGHT}
                />

                {Boolean(svg) && (
                    <img
                        className="custom-pin"
                        color="white"
                        style={{ fill: "white" }}
                        src={svg!}
                        width={16}
                        height={16}
                        alt={"partner pin"}
                    />
                )}
            </div>
        </div>
    );
}

export function shadeColor(color: string, percent: number) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(((R * (100 + percent)) / 100).toString(), 10);
    G = parseInt(((G * (100 + percent)) / 100).toString(), 10);
    B = parseInt(((B * (100 + percent)) / 100).toString(), 10);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR =
        R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    var GG =
        G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    var BB =
        B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
}

export default React.memo(CustomPin);
