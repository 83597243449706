import { BBox } from "geojson";
import Supercluster from "supercluster";

export default function useSupercluster() {
    const index = new Supercluster({
        maxZoom: 14,
        radius: 40,
    });

    function load(
        points: Array<Supercluster.PointFeature<{ venueId: string }>>
    ) {
        index.load(points);
    }

    function get(bbox: BBox, zoom: number) {
        return index.getClusters(bbox, zoom);
    }

    function getZoom(clusterId: number) {
        return index.getClusterExpansionZoom(clusterId);
    }

    return {
        load,
        get,
        getZoom,
    };
}
