import { Venues, Venue, Longitude, Latitude } from "../types";
import { GET_EVENTS } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { startOfDay, endOfDay, add } from "date-fns";

interface FetchProps {
    dateTimeFilter: string;
    radius: number;
    longitude: number;
    latitude: number;
    activeFilters: ReadonlyArray<string>;
}

export interface PinType {
    id: string;
    name: string;
    description: string;
    buttonText: string;
    location: {
        coordinates: [Longitude, Latitude];
    };
    color: string;
    svgUrl: string;
    link?: string;
}

export type Pins = ReadonlyArray<PinType>;

export default function useVenues() {
    const [getEvents, { data, loading, error }] = useLazyQuery<{
        venues: Venues;
        pins: Pins;
    }>(GET_EVENTS);

    const fetchedVenues = data?.venues;
    const fetchedPins = data?.pins;

    const [venues, setVenues] = useState<Set<Venue>>(new Set());
    const [pins, setPins] = useState<Set<PinType>>(new Set());

    function fetch({
        dateTimeFilter,
        radius,
        longitude,
        latitude,
        activeFilters,
    }: FetchProps) {
        if (radius > 50000) {
            return;
        }

        const { startDate, endDate } = getStartEndDate(dateTimeFilter);

        getEvents({
            variables: {
                venuesInput: {
                    radius: Math.floor(radius / 1000),
                    categoryIds:
                        activeFilters && !activeFilters.includes("ALL")
                            ? activeFilters
                            : undefined,
                    startDate,
                    endDate,
                    longitude,
                    latitude,
                },
                pinsInput: {
                    radius: Math.floor(radius / 1000),
                    longitude,
                    latitude,
                },
            },
        });
    }

    useEffect(() => {
        if (!loading && fetchedVenues) {
            setVenues(new Set(fetchedVenues));
        }

        if (!loading && fetchedPins) {
            setPins(new Set(fetchedPins));
        }
    }, [setVenues, loading, error, fetchedVenues, setPins, fetchedPins]);

    const stableFetch = useCallback(fetch, [getEvents]);

    return {
        venues: [...venues],
        pins: [...pins],
        fetch: stableFetch,
        loading,
    };
}

export function getStartEndDate(when: string) {
    switch (when) {
        case "TODAY":
            return {
                startDate: startOfDay(new Date()),
                endDate: endOfDay(new Date()),
            };
        case "TOMORROW":
            return {
                startDate: startOfDay(add(new Date(), { days: 1 })),
                endDate: endOfDay(add(new Date(), { days: 1 })),
            };

        case "WEEK":
            return {
                startDate: startOfDay(new Date()),
                endDate: endOfDay(add(new Date(), { weeks: 1 })),
            };
    }

    return {
        startDate: startOfDay(new Date()),
        endDate: add(new Date(), { months: 3 }),
    };
}
