import { gql } from "@apollo/client";

export const GET_EVENTS = gql`
    query GetEvents($venuesInput: VenuesInput!, $pinsInput: PinsInput!) {
        pins(pinsInput: $pinsInput) {
            id
            name
            description
            addressText
            buttonText
            location {
                coordinates
            }
            svgUrl
            color
            link
        }
        venues(venuesInput: $venuesInput) {
            id
            name
            addressText
            description
            link
            location {
                coordinates
            }
            events(venuesInput: $venuesInput) {
                id
                name
                imageUrl
                description
                ticketPurchaseUrl
                startDate
                endDate
                link
                affiliateUrl
                affiliateText
                affiliateSvgUrl
                affiliateSvgColor
                affiliateButtonColor
                supportText
                supportSvgUrl
                customPinColor
                category {
                    imageUrl
                    id
                    svg
                    svgUrl
                    name
                }
            }
        }
    }
`;
