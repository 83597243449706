import { SVGProps } from "react";

function PinOutline(props: SVGProps<SVGElement>) {
    return (
        <svg
            className="pinOutline"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            version="1.1"
            viewBox="0 0 608.17 910.42"
            fill={props.fill}
        >
            <path d="M304.1 910.42c-9 0-16.28-5.57-31.12-23.81-9.66-11.88-22.09-28.77-36-48.84-31.82-46.1-66.83-102.4-98.58-158.52-39.12-69.17-72-136-95.09-193.18C14.59 414.77 0 356.41 0 312.61c0-83.92 30.92-162.33 87.07-220.8C143.92 32.61 220.99 0 304.07 0c83.08 0 160.21 32.61 217.03 91.81 56.15 58.47 87.07 136.88 87.07 220.8 0 43.8-14.59 102.16-43.35 173.46-23.08 57.21-56 124-95.08 193.18-31.75 56.14-66.76 112.43-98.58 158.52-13.86 20.08-26.29 37-36 48.84-14.77 18.24-22.06 23.81-31.06 23.81zM447.02 650.3c-298.013-433.533-149.007-216.767 0 0z"></path>
        </svg>
    );
}

export default PinOutline;
