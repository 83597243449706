import { format } from "date-fns";
import { Event } from "../../types";
import { formatStartEndDate } from "../../utils";
import { ReactComponent as CalendarIcon } from "../icons/calendar.svg";
import { ReactComponent as TimeIcon } from "../icons/time.svg";

interface Props {
    event: Event;
    clickable?: boolean;
    onPopup?(): void;
}

export default function DateTime({ event, clickable, onPopup }: Props) {
    const parsedDate = new Date(event.startDate);
    const parsedEndDate = event?.endDate ? new Date(event.endDate) : undefined;

    function onPress() {
        //addToCalendar(event);
    }

    return (
        <div>
            <div>
                <p className="icon-and-text">
                    <TimeIcon
                        width={"1em"}
                        height={"1em"}
                        fill="#333333"
                        color="#333333"
                    />
                    {format(parsedDate, "HH:mm")}
                </p>
            </div>

            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                        width: "100%",
                    }}
                >
                    <p className="icon-and-text">
                        <CalendarIcon
                            width={"1em"}
                            height={"1em"}
                            fill="#333333"
                            color="#333333"
                        />
                        {formatStartEndDate(parsedDate, parsedEndDate)}
                    </p>
                    {clickable && (
                        <a
                            href="#"
                            style={{ textDecoration: "none" }}
                            onClick={onPopup}
                        >
                            Add to calendar
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}
