import { ReactComponent as Playstore } from "../icons/playstore.svg";
import { ReactComponent as Appstore } from "../icons/appstore.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";

export default function Popup({
    visible,
    onClose,
}: {
    visible: boolean;
    onClose(): void;
}) {
    if (!visible) return null;

    return (
        <div>
            <div className="modal-overlay"></div>
            <div className="modal-wrapper">
                <div className="modal">
                    <button className="close" onClick={onClose}>
                        <CloseIcon
                            width={20}
                            height={20}
                            fill="#333333"
                            color="#333333"
                        />
                    </button>
                    <h1>Download Sidelyne</h1>
                    <p style={{ lineHeight: "1.5em" }}>
                        Want the full experience? It's free, no registration
                        required. <br /> Download, open and go visit sport
                        events!
                    </p>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        }}
                    >
                        <a
                            href="https://apps.apple.com/us/app/sidelyne/id1582354561"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <img
                                style={{ paddingRight: 5 }}
                                height="60px"
                                src={require("../icons/appstore.png")}
                            />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=app.sidelyne"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <img
                                height="60px"
                                src={require("../icons/playstore.png")}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
