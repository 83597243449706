import { useEffect, useState } from "react";

export default function useGeolocation() {
    const [location, setLocation] = useState<GeolocationPosition>();

    function onPosition(position: GeolocationPosition) {
        setLocation(position);
    }

    function onPositionError(position: GeolocationPositionError) {
        console.log("geo position error");
    }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(onPosition, onPositionError);
    }, []);

    return location;
}
