import { format, getDate } from "date-fns";

export function formatStartEndDate(startDate: Date, endDate?: Date) {
    try {
        const startDay = getDate(startDate);
        const endDay = endDate ? getDate(endDate) : undefined;

        const dateText =
            endDate && startDay !== endDay
                ? `${("0" + startDay).slice(-2)} - ${("0" + endDay).slice(
                      -2
                  )} ${format(startDate, "LLL yyyy")}`
                : format(startDate, "dd LLL yyyy");

        return dateText;
    } catch (err) {
        return "";
    }
}
