import { useEffect, useState } from "react";
import { Event, Venue } from "../../types";
import DateTime from "../DateTime/DateTime";
import Link from "../Link/Link";
import Location from "../Location/Location";

import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as BackIcon } from "../icons/back.svg";
import { PinType } from "../../hooks/useVenues";

import ReactMarkdown from "react-markdown";

type Props = {
    venue?: Venue;
    customPin?: PinType;
    onClose(): void;
    onPopup(): void;
};
export default function Sidebar({ venue, onClose, customPin, onPopup }: Props) {
    const [selectedEvent, setSelectedEvent] = useState<Event>();

    useEffect(() => {
        setSelectedEvent(undefined);
    }, [venue]);

    function onSelect(event: Event) {
        setSelectedEvent(event);
    }

    return (
        <div className={`sidebar ${venue || customPin ? "open" : ""}`}>
            {selectedEvent && (
                <button
                    className="back"
                    onClick={() => {
                        setSelectedEvent(undefined);
                    }}
                >
                    <BackIcon
                        width={20}
                        height={20}
                        fill="#333333"
                        color="#333333"
                    />
                </button>
            )}
            <button
                className="close"
                onClick={() => {
                    setSelectedEvent(undefined);
                    onClose();
                }}
            >
                <CloseIcon
                    width={20}
                    height={20}
                    fill="#333333"
                    color="#333333"
                />
            </button>
            {customPin && (
                <div className="venue-content">
                    <CustomPinContent customPin={customPin} />
                </div>
            )}
            {venue && (
                <div className="venue-content">
                    <VenueContent
                        onPopup={onPopup}
                        selectedEvent={selectedEvent}
                        onSelect={onSelect}
                        venue={venue}
                    />
                </div>
            )}
        </div>
    );
}

function VenueContent({
    venue,
    onSelect,
    selectedEvent,
    onPopup,
}: {
    venue: Venue;
    onSelect(event: Event): void;
    selectedEvent?: Event;
    onPopup(): void;
}) {
    if (venue.events.length === 1 || selectedEvent) {
        const event = selectedEvent || venue.events[0];
        return (
            <div className="venue-details">
                <div className="icon-and-text">
                    <h1 style={{ paddingLeft: 15 }}>
                        <img
                            alt={`${event.category.name} logo`}
                            width={24}
                            height={24}
                            style={{ marginRight: 10 }}
                            src={event.category.svgUrl}
                        />
                        {event.category.name}
                    </h1>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        alt={`event logo`}
                        style={{
                            maxWidth: "100%",
                            maxHeight: 200,
                        }}
                        src={event.imageUrl || event.category.imageUrl}
                    />
                </div>
                <h2 style={{ textAlign: "center" }}>{event.name}</h2>
                {event.description && <p>{event.description}</p>}
                <hr style={{ opacity: 0.2 }} />
                {event.link && <Link href={event.link}>{event.link}</Link>}
                <Location onPopup={onPopup}>{venue.name}</Location>
                <hr style={{ opacity: 0.2 }} />
                <DateTime onPopup={onPopup} clickable={true} event={event} />

                {event.ticketPurchaseUrl && (
                    <a
                        // href={event.ticketPurchaseUrl}
                        href="#"
                        onClick={onPopup}
                        className="button"
                    >
                        Buy ticket
                    </a>
                )}
                <hr style={{ opacity: 0.2 }} />

                {Boolean(event?.affiliateUrl) && Boolean(event?.affiliateText) && (
                    <>
                        <a
                            href={event.affiliateUrl}
                            target="_blank"
                            className="button"
                            style={{
                                backgroundColor: event?.affiliateButtonColor,
                                color: event?.affiliateSvgColor,
                            }}
                            rel="noreferrer"
                        >
                            <span
                                style={{
                                    display: "inline-flex",
                                    verticalAlign: "middle",
                                }}
                            >
                                <img
                                    alt="affiliate logo"
                                    className="white"
                                    style={{ marginRight: 10 }}
                                    src={event?.affiliateSvgUrl}
                                    width={18}
                                    height={18}
                                />
                                {event?.affiliateText}
                            </span>
                        </a>
                        <div>
                            {event?.supportText && <p>{event?.supportText}</p>}
                            {event?.supportSvgUrl && (
                                <img
                                    alt="support logo"
                                    className="svg"
                                    src={event?.supportSvgUrl}
                                    width={80}
                                    height={18}
                                />
                            )}
                        </div>
                        <hr style={{ opacity: 0.2 }} />
                    </>
                )}

                <span
                    style={{
                        textAlign: "center",
                        display: "block",
                        fontSize: 12,
                        opacity: 0.4,
                    }}
                >
                    Games are subject to change and local health regulations
                </span>
                <br />
                <span
                    style={{
                        textAlign: "center",
                        display: "block",
                        fontSize: 12,
                        opacity: 0.4,
                    }}
                >
                    Clubs can join Sidelyne and earn commission on top
                </span>
                <a
                    style={{
                        textAlign: "center",
                        fontSize: 12,
                        display: "block",
                        color: "#138601",
                    }}
                    href="https://www.sidelyne.app/NL/decathlon/index.php"
                    target="_blank"
                    rel="noreferrer"
                >
                    Learn more
                </a>
            </div>
        );
    }

    return (
        <div className="venue-list">
            {venue.events.map((event) => (
                <div className="event-list-item card card-container">
                    <div className="icon-and-text">
                        <h3>
                            <img
                                width={16}
                                height={16}
                                style={{ marginRight: 4 }}
                                alt={`${event.category.name} logo`}
                                src={event.category.svgUrl}
                            />
                            {event.category.name}
                        </h3>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                            style={{
                                maxWidth: "100%",
                            }}
                            src={event.imageUrl || event.category.imageUrl}
                        />
                    </div> */}
                    <h2>{event.name}</h2>
                    <DateTime event={event} />
                    <button className="button" onClick={() => onSelect(event)}>
                        Details
                    </button>
                </div>
            ))}
        </div>
    );
}

function CustomPinContent({ customPin }: { customPin: PinType }) {
    return (
        <div className="venue-details">
            <div className="icon-and-text">
                {/* <img
                    alt={`${event.category.name} logo`}
                    width={20}
                    height={20}
                    src={event.category.svgUrl}
                /> */}
                <h1 style={{ paddingLeft: 15 }}>{customPin.name}</h1>
            </div>
            <ReactMarkdown>{customPin.description}</ReactMarkdown>
            {Boolean(customPin?.link) && (
                <a
                    className="button"
                    style={{
                        backgroundColor: customPin.color,
                        color: "white",
                    }}
                    href={customPin?.link}
                >
                    {customPin.buttonText}
                </a>
            )}
        </div>
    );
}
