import React from "react";

function ClusterMarker({ count }: { count: number }) {
    return (
        <svg style={{ cursor: "zoom-in" }} height="60" width="60">
            <circle
                cx="30"
                cy="30"
                r="30"
                stroke="white"
                strokeWidth="1"
                fill="green"
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                fill="white"
                fontSize={"24"}
                dy=".3em"
            >
                {count}
            </text>
        </svg>
    );
}

export default React.memo(ClusterMarker);
