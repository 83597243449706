import Mapview from "./components/Map/Map";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { useState } from "react";
import Popup from "./components/Popup/Popup";

const client = new ApolloClient({
    uri: "https://api.sidelyne.app/graphql",
    cache: new InMemoryCache(),
});

function App() {
    const [popupVisible, setPopupVisible] = useState(false);

    return (
        <ApolloProvider client={client}>
            <Mapview onPopup={() => setPopupVisible(true)} />
            <Popup
                onClose={() => setPopupVisible(false)}
                visible={popupVisible}
            />
        </ApolloProvider>
    );
}

export default App;
