import { SVGProps } from "react";

function MultiplePins(props: SVGProps<SVGElement>) {
    return (
        <svg
            className="multiplePinsOutline"
            viewBox="0 0 835.44238 911.99182"
            width={props.width}
            height={props.height}
            fill={props.fill}
            stroke={props.stroke}
        >
            <path d="M531.372 911.992c-9 0-16.28-5.57-31.12-23.81-9.66-11.88-22.09-28.77-36-48.84-31.82-46.1-66.83-102.4-98.58-158.52-39.12-69.17-72-136-95.09-193.18-28.72-71.3-43.31-129.66-43.31-173.46 0-83.92 30.92-162.33 87.07-220.8 56.85-59.2 133.92-91.81 217-91.81 83.08 0 160.21 32.61 217.03 91.81 56.15 58.47 87.07 136.88 87.07 220.8 0 43.8-14.59 102.16-43.35 173.46-23.08 57.21-56 124-95.08 193.18-31.75 56.14-66.76 112.43-98.58 158.52-13.86 20.08-26.29 37-36 48.84-14.77 18.24-22.06 23.81-31.06 23.81zm142.92-260.12c-298.013-433.534-149.006-216.767 0 0z" />
            <path
                d="M336.944 910.42c-9 0-16.28-5.57-31.12-23.81-9.66-11.88-22.09-28.77-36-48.84-31.82-46.1-66.83-102.4-98.58-158.52-39.12-69.17-72-136-95.09-193.18-28.72-71.3-43.31-129.66-43.31-173.46 0-83.92 30.92-162.33 87.07-220.8 56.85-59.2 133.92-91.81 217-91.81 83.08 0 160.21 32.61 217.03 91.81 56.15 58.47 87.07 136.88 87.07 220.8 0 43.8-14.59 102.16-43.35 173.46-23.08 57.21-56 124-95.08 193.18-31.75 56.14-66.76 112.43-98.58 158.52-13.86 20.08-26.29 37-36 48.84-14.77 18.24-22.06 23.81-31.06 23.81zm142.92-260.12c-298.013-433.533-149.006-216.767 0 0z"
                fill="#fff"
                fillOpacity={1}
            />
            <path d="M304.1 911.38c-9 0-16.28-5.57-31.12-23.81-9.66-11.88-22.09-28.77-36-48.84-31.82-46.1-66.83-102.4-98.58-158.52-39.12-69.17-72-136-95.09-193.18C14.59 415.73 0 357.37 0 313.57c0-83.92 30.92-162.33 87.07-220.8C143.92 33.57 220.99.96 304.07.96c83.08 0 160.21 32.61 217.03 91.81 56.15 58.47 87.07 136.88 87.07 220.8 0 43.8-14.59 102.16-43.35 173.46-23.08 57.21-56 124-95.08 193.18-31.75 56.14-66.76 112.43-98.58 158.52-13.86 20.08-26.29 37-36 48.84-14.77 18.24-22.06 23.81-31.06 23.81zm142.92-260.12c-298.013-433.534-149.007-216.767 0 0z" />
        </svg>
    );
}

export default MultiplePins;
