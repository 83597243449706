import { ReactComponent as LinkIcon } from "../icons/link.svg";

interface Props {
    children: React.ReactNode;
    href: string;
}

export default function Link({ children, href }: Props) {
    return (
        <div>
            <a href={href} target="_blank" rel="noreferrer">
                <p className="icon-and-text">
                    <LinkIcon
                        width={"1em"}
                        height={"1em"}
                        fill="#333333"
                        color="#333333"
                    />
                    {children}
                </p>
            </a>
        </div>
    );
}
