import { useEffect, useRef, useState } from "react";
import Map, {
    GeolocateControl,
    MapRef,
    Marker,
    ViewState,
    ViewStateChangeEvent,
} from "react-map-gl";
import { Venue } from "../../types";
import useVenues from "../../hooks/useVenues";
import "./map.css";
import useResize from "../../hooks/useResize";
import useSupercluster from "../../hooks/useSupercluster";
import { AnyProps, ClusterFeature, PointFeature } from "supercluster";
import Pin from "../Pin";
import useGeolocation from "../../hooks/useGeolocation";
import Sidebar from "../Sidebar/Sidebar";
import ClusterMarker from "../ClusterMarker";
import CustomPin from "../CustomPin";

function toPoint(venue: Venue): PointFeature<{ venueId: string }> {
    return {
        type: "Feature",
        geometry: { type: "Point", coordinates: venue.location.coordinates },
        properties: {
            venueId: venue.id,
        },
    };
}

function getInitialState() {
    const search = new URLSearchParams(window.location.search);

    const lat = parseFloat(search.get("lat") || "");
    const lng = parseFloat(search.get("lng") || "");

    if (isNaN(lat) || isNaN(lng)) {
        return {
            latitude: 52.370216,
            longitude: 4.895168,
            zoom: 10,
        };
    }

    return {
        latitude: lat,
        longitude: lng,
        zoom: 10,
    };
}

export default function Mapview({ onPopup }: { onPopup(): void }) {
    const [coordsFromUrl, setCoordsFromUrl] = useState(() =>
        Boolean(new URLSearchParams(window.location.search).get("lat"))
    );

    const [selectedVenueId, setSelectedVenueId] = useState<Venue["id"]>();
    const [selectedCustomPinId, setSelectedCustomPinId] = useState<string>();
    const geolocation = useGeolocation();
    const [geoUsed, setGeoUsed] = useState(false);
    const mapRef = useRef<MapRef>(null);
    const { venues, fetch, pins } = useVenues();
    const [moving, setMoving] = useState(false);
    const { load, get, getZoom } = useSupercluster();
    const selectedVenue = venues.filter(
        (venue) => venue.id === selectedVenueId
    );
    const { width, height } = useResize();

    const radius = Math.min(width, height) / 2;

    const [viewState, setViewState] = useState<Partial<ViewState> | undefined>(
        getInitialState
    );

    const points = venues.map(toPoint);

    load(points);

    const southEast = mapRef.current?.getBounds().getSouthEast();
    const northEast = mapRef.current?.getBounds().getNorthEast();
    const southWest = mapRef.current?.getBounds().getSouthWest();
    const northWest = mapRef.current?.getBounds().getNorthWest();

    const bbox = [
        mapRef.current?.getBounds().getWest() || 0,
        mapRef.current?.getBounds().getSouth() || 0,
        mapRef.current?.getBounds().getEast() || 0,
        mapRef.current?.getBounds().getNorth() || 0,
    ] as GeoJSON.BBox;

    const markers = bbox ? get(bbox, mapRef.current?.getZoom() || 10) : [];

    let distance: number | undefined;
    // if the longest side is the height then we should determine distance based on left to right
    if (window.innerHeight > window.innerWidth) {
        distance = northEast?.distanceTo(northWest!);
    }

    // if the longest side is width then we should determine distance based on top to bottom
    if (window.innerWidth > window.innerHeight) {
        distance = northEast?.distanceTo(southEast!);
    }

    if (distance) {
        // distance in meters
        distance = Math.ceil(distance / 2);
    }

    const distanceInKM = distance ? Math.ceil(distance / 1000) : 0;

    const selectedCustomPin = pins.find(
        (pin) => pin.id === selectedCustomPinId
    );

    const onClusterClick =
        (cluster: ClusterFeature<AnyProps> | PointFeature<AnyProps>) => () => {
            const clusterId = cluster.properties?.cluster_id;

            const zoom = getZoom(clusterId);

            mapRef.current?.easeTo({
                center: {
                    lat: cluster.geometry.coordinates[1],
                    lng: cluster.geometry.coordinates[0],
                },
                zoom,
                duration: 500,
            });
        };

    useEffect(() => {
        if (
            geoUsed ||
            !geolocation?.coords?.latitude ||
            !geolocation?.coords?.longitude
        ) {
            return;
        }

        if (coordsFromUrl) {
            return;
        }

        setGeoUsed(true);

        const newViewState = {
            longitude: geolocation?.coords?.longitude,
            latitude: geolocation?.coords?.latitude,
            zoom: 10,
        };

        setViewState(newViewState);
    }, [geolocation, geoUsed, viewState, coordsFromUrl]);

    useEffect(() => {
        if (moving) {
            return;
        }

        window.history.replaceState(
            null,
            "",
            `?lat=${viewState?.latitude}&lng=${viewState?.longitude}&radius=${distance}`
        );
    }, [viewState, moving, distance]);

    useEffect(() => {
        if (moving) {
            return;
        }

        if (viewState?.longitude && viewState?.latitude) {
            fetch({
                dateTimeFilter: "ALL",
                radius: distance || 20000,
                longitude: viewState.longitude,
                latitude: viewState.latitude,
                activeFilters: ["ALL"],
            });
        }
    }, [viewState, fetch, moving, distance]);

    function onMove(event: ViewStateChangeEvent) {
        setMoving(true);
        setViewState(event.viewState);
    }

    function onMoveEnd(event: ViewStateChangeEvent) {
        setMoving(false);
    }

    return (
        <>
            <Map
                {...viewState}
                mapboxAccessToken="pk.eyJ1Ijoia29vbGFibGUxMjMiLCJhIjoiY2w4aXVwOHFsMG1obDNubGM2dnRwY2M2cCJ9.yUjPzRPVaamXb9eNyEak-A"
                style={{ width: "100vw", height: "100vh" }}
                mapStyle="mapbox://styles/koolable123/cl8iurwaa001s15o53j9z43mf"
                onMoveEnd={onMoveEnd}
                onMove={onMove}
                ref={mapRef}
            >
                <GeolocateControl />
                {geolocation?.coords?.latitude && (
                    <Marker
                        key={"me"}
                        latitude={geolocation?.coords?.latitude}
                        longitude={geolocation?.coords?.longitude}
                    />
                )}
                {pins.map((pin) => (
                    <Marker
                        latitude={pin.location.coordinates[1]}
                        longitude={pin.location.coordinates[0]}
                        key={pin.id}
                        onClick={() => {
                            setSelectedCustomPinId(pin.id);
                            setSelectedVenueId(undefined);
                        }}
                    >
                        <CustomPin
                            color={pin.color}
                            svg={pin.svgUrl}
                            active={false}
                        />
                    </Marker>
                ))}
                {markers.map((marker) => {
                    if (marker.properties.cluster) {
                        return (
                            <Marker
                                latitude={marker.geometry.coordinates[1]}
                                longitude={marker.geometry.coordinates[0]}
                                key={marker.properties.cluster_id}
                                onClick={onClusterClick(marker)}
                            >
                                <ClusterMarker
                                    count={
                                        marker.properties
                                            .point_count_abbreviated
                                    }
                                />
                            </Marker>
                        );
                    }

                    return (
                        <Marker
                            latitude={marker.geometry.coordinates[1]}
                            longitude={marker.geometry.coordinates[0]}
                            key={marker.properties.venueId}
                            onClick={() => {
                                setSelectedCustomPinId(undefined);
                                setSelectedVenueId(marker.properties.venueId);
                            }}
                        >
                            <Pin
                                events={
                                    venues.find(
                                        (venue) =>
                                            venue.id ===
                                            marker.properties.venueId
                                    )?.events || []
                                }
                                active={
                                    selectedVenueId ===
                                    marker.properties.venueId
                                }
                            />
                        </Marker>
                    );
                })}
            </Map>

            {distanceInKM > 5 && (
                <div className="overlay">
                    {distance && <p className="radius">{distanceInKM} km</p>}
                    <svg
                        viewBox={`0 0 ${width} ${height}`}
                        width="100vw"
                        height="100vh"
                    >
                        <defs>
                            <mask
                                id="mask"
                                x="0"
                                y="0"
                                width={width}
                                height={height}
                            >
                                <rect
                                    width={width}
                                    height={height}
                                    fill="#fff"
                                />
                                <circle
                                    cx={width / 2}
                                    cy={height / 2}
                                    r={radius - 25}
                                />
                            </mask>
                        </defs>
                        <rect
                            x="0"
                            y="0"
                            width={width}
                            height={height}
                            mask="url(#mask)"
                            fillOpacity="0.4"
                        />
                    </svg>
                </div>
            )}
            <Sidebar
                onPopup={onPopup}
                venue={selectedVenue?.[0]}
                customPin={selectedCustomPin}
                onClose={() => {
                    setSelectedCustomPinId("");
                    setSelectedVenueId("");
                }}
            />
        </>
    );
}
